@import url('https://fonts.googleapis.com/css2?family=Rajdhani&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap');

@font-face {
  font-family: Frutiger Next Regular;
  src: url("./res/fonts/frutigernextregular.ttf");
}
@font-face {
  font-family: Frutiger Next Light;
  src: url("./res/fonts/frutigernextlight.ttf");
}
@font-face {
  font-family: Frutiger Next Bold;
  src: url("./res/fonts/frutigernextbold.ttf");
}

* {

  transition: 250ms;
  font-family: Frutiger Next Regular;

}

a {
  color: black;
}

body {

  margin: 0;
  width: 100vw;

}

nav {

  background: #ffffff;
  position: fixed;
  width: 100vw;
  top: 0;
  display: grid;
  align-items: center;
  justify-items: start;
  grid-template: 1vh 4vh 1vh / 1vh 2.5vh 1.2vh 16vh 1fr;
  transition: 0s;
  z-index: 100;


}

nav .links {

  display: none;

}

#menunav {

  width: 2.5vh;
  grid-row: 2;
  grid-column: 2;

}

#menunav.menu-open {

  content: url("./res/close.png");
  animation: spin 200ms;

}

#menunav.menu-closed {

  content: url("./res/menu.png");
  animation: spin2 200ms ;

}

@keyframes spin {
  from {transform: rotate(0);}
  to {transform: rotate(180deg);}
}
@keyframes spin2 {
  from {transform: rotate(180deg);}
  to {transform: rotate(0);}
}


@keyframes fadeslide {
  from {
    transform: rotate(-10deg) scale(0.3);
    opacity: 0;
  }
  to {
    transform: rotate(0deg) scale(1);
    opacity: 1;
  }
}

@keyframes fadeslide2 {
  from {
    transform: rotate(10deg) scale(0.3);
    opacity: 0;
  }
  to {
    transform: rotate(0deg) scale(1);
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slidein {
  from {
    transform: scale(0, 1);
  }
  to {
    transform: scale(1, 1);
  }
}

@media screen and (min-width: 10000001px){

  nav {

    background: #fffffff;
    position: fixed;
    width: 100vw;
    height: 10vh;
    top: 0;
    display: grid;
    align-items: center;
    justify-items: end;
    grid-template: 1fr 3fr 1fr / 0.2fr 3vh 0.1fr 10fr 0.1fr 4fr 0.2fr;
    transition: 0s;
    z-index: 100;

  }
}

.active {transform: scale(1, 1) !important;}

@media (max-width: 650px){
  #menuwindow {
    width: 100vw; 
    padding: 10vh 0
  }
  #linksinmenu * {

    font-size: 40px;
    font-family: Frutiger Next Regular;
    color: #fff;
    text-decoration: underline transparent;
    transition: 250ms;
    padding: 0 30px;
    line-height: 70px;

  }

  #linksinmenu *:hover {

    font-size: 50px;
    color: white;
    text-decoration: underline #f00;

  }
}
@media (min-width: 651px){
  #menuwindow {width: 270px;}

  #linksinmenu * {

    font-size: 20px;
    font-family: Frutiger Next Regular;
    color: #fff;
    text-decoration: underline transparent;
    transition: 250ms;
    padding: 0 30px;
    line-height: 34px;
  }

  #linksinmenu *:hover {

    font-size: 150%;
    color: white;
    text-decoration: underline #f00;

  }


}

#menuwindow {

  position: fixed;
  left: 0;
  top: 6vh;
  background: #333333bb;
  max-height: 80vh;
  transform: scale(0, 1);
  transform-origin: 0% 100%;
  transition: 80ms;
  z-index: 100;
  justify-items: center;
  display: flex;
  backdrop-filter: blur(20px);

}


#closebtn {

  width: 2.5vh;
  filter: invert(100%);
  grid-row: 2;
  grid-column: 4;
  align-self: center;

}

#linksinmenu {

  padding: 30px 0;

}

nav * {

  transition: 0s;
  color: black;

}

nav .links * {

  font-size: 2vh;
  font-family: Rajdhani;
  color: ;
  text-decoration: underline transparent;
  transition: 250ms;


}

nav .links *:hover {

  font-size: 1.9vw;
  color: black;
  text-decoration: underline #f00;

}

#logonav {

  width: 16vh;
  grid-row: 2;
  grid-column: 4;

}

#logonava {

  width: 16vh;
  grid-row: 2;
  grid-column: 4;
  font-size: 0;

}

#contactform {

  padding: 2%;
  margin: auto;
  position: absolute;
  top: 12.5vh;
  bottom: 6.5vh;
  left: 0;
  right: 0;
  border: solid white 2px;
  width: 60vw;
  animation: fadeslide 500ms;

}

#contactform h1 {

  text-align: left;
  font-family: Open Sans;
  font-size: 2.5vh;
  color: black;
  font-weight: lighter;
  grid-row: 1;
  grid-column: 2;

}

#contactform h2 {

  text-align: left;
  font-size: 2vh;
  color: black;
  font-family: Frutiger Next Light;

}

#contactform a h2 {

  text-align: left;
  font-size: 1.8vh;
  color: #333;
  font-family: Frutiger Next Light;

}

#contactform a {

  text-decoration: underline transparent;
  transition: 250ms;

}


#contactform a:hover {

  text-decoration: underline black;

}

#loginform {

  padding: 2%;
  margin: auto;
  position: absolute;
  top: 12.5vh;
  bottom: 6.5vh;
  left: 0;
  height: auto;
  overflow: hidden;
  right: 0;
  border: solid white 2px;
  display: grid;
  grid-template: 4fr 4vh 10vh 4vh 20vh 40vh / 1fr 50vw 1fr;
  align-items: center;
  justify-items: flex-start;
  width: 60vw;
  animation: fadeslide 500ms;

}

.link2 {

  text-decoration: none;

}


#loginform h1 {

  text-align: left;
  font-family: Open Sans;
  font-size: 2.5vh;
  color: black;
  font-weight: lighter;
  grid-row: 1;
  grid-column: 2;

}

#loginform h2 {

  text-align: left;
  font-size: 2vh;
  color: black;
  font-family: Frutiger Next Light;
  grid-row: 3 !important;

}

#stage-home h1 {

  text-align: left;
  font-family: Open Sans;
  font-size: 3.5vh;
  color: black;
  grid-row: 1;
  grid-column: 2;

}

#stage-home p {

  text-align: left;
  font-size: 2vh;

}

#logonavspan {

  display: inline;

  grid-column: 2;

}

#link1, #link2 {

  grid-row: 2;
  text-decoration: underline transparent;
  transition: 250ms !important;
  font-size: 3vh;
  font-family: Rajdhani;

}


nav input:focus {

  outline-width: 0;
  font-size: 2.5vh !important;
  border-bottom: solid #fff 1px;

}

.add:hover, .add:active {

  color: #00ff00;

}

.edit:hover, .edit:active {

  color: #ffff00;

}

.delete:hover, .delete:active {

  color: #ff0000;

}

#formdiv {

  grid-column: 2 !important;
  grid-row: 5 !important;
  max-width: 60vw;
}

.formcontact input, .apiinput input {

  background: transparent;
  font-size: 2.6vh;
  border: none;
  border-bottom: solid #8a7342 1px;
  outline-width: 0;
  color: black;
  text-align: left;

}

.apiinput form, .formcontact, .apiinput {

  display: flex;
  align-items: flex-start;
  flex-direction: column;

}

.formcontact input:focus, .apiinput input:focus {

  font-size: 3.5vh;
  border-bottom: solid #000 3px;

}

footer {

  display: flex;
  justify-content: space-evenly;
  align-items: center;
  position: fixed;
  bottom: 0;
  height: 6vh;
  width: 100vw;
  background: #333333bb;
  backdrop-filter: blur(1vh) ;
  z-index: 999;

}

footer *:hover, footer *:active {

  transform: scale(1.3);
  color: #fff;
  text-decoration-color: black;

}

footer * {

  color: #ccc;
  font-size: 2vh;
  text-decoration-color: transparent;

}

#list {

  width: 70%;

}

#list td {

  padding: 1%;
  color: black;

}

#list thead {

  font-weight: bold;
  border-bottom: solid white 2px;

}

.form-prompt {

  line-height: 2;
  color: #ff0000;

}

.success-message {

  color: #00cc00;
  padding-top: 1vh;

}

.banner {

  width: 100vw;
  left: 0;
  margin: 0;
  margin-top: 6vh;
  font-size: 0;

} 

.bannerimg {

  content: url("./res/banner.jpg");
  width: 100vw;
  animation: fadein 800ms;

}

@media (max-width: calc(1080px - 10px)){

  .bannertitle {

    width: 100vw;
    background: #807466;
    height: auto;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    padding: 3vw;
    box-sizing: border-box;

  }

  #stage-home {

    padding: 5%;
    margin: 0;
    z-index: -1000;
    align-items: start;
    justify-items: center;
    width: 75vw;

  }

}

@media (min-width: calc(1080px - 10px + 1px)){

  .bannertitle {

    width: 85vw;
    background: #807466;
    height: auto;
    margin: auto;
    position: relative;
    top: -50px;
    float: right;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    padding: 3vw;
    box-sizing: border-box;
    transform-origin: 100% 0%;

    animation: slidein 300ms cubic-bezier(0.39, 0.75, 0, 0.93);

  }

  #stage-home {

    padding: 2%;
    margin: 0;
    z-index: -1000;
    align-items: start;
    justify-items: center;
    width: 85vw;
    float: right;
    box-sizing: border-box;

  }

}

@media (orientation: landscape){

  .bannertitle h1 {

    margin: 0;
    color: white;
    font-size: 3.4vh;

  }

  .bannertitle span * {

    color: white;
    font-size: 2vh ;

  }
  
}

@media (orientation: portrait){

  .bannertitle h1 {

    margin: 0;
    color: white;
    font-size: 5vw;

  }

  .bannertitle span * {

    color: white;
    font-size: 3vw;
    overflow: hidden;

  }
  
}

.bannertitle span a {

  text-decoration: underline transparent;

}

.bannertitle span a:hover {

  text-decoration: underline white;

}

.li:before {

  content: url("res/gt.png");
  vertical-align: middle;
  filter: invert(1);

}

.li {

  display: inline;
  font-weight: bold;

}

.section {

  padding: 2%;
  left: 0;
  z-index: -1000;
  animation: fadeslide 500ms;
  align-items: center;
  justify-items: center;
  width: 80vw;

}

.section h1 {

  text-align: center;
  font-family: Open Sans;
  font-size: 3.5vh;
  color: black;
  grid-row: 1;
  grid-column: 2;

}

.section h2 {

  text-align: center;
  font-family: Frutiger Next Regular;
  font-size: 2vh;
  color: black;
  grid-column: 2;
  grid-row: 2;

}

.section * {

  text-align: center;
  font-family: Open Sans;
  font-size: 2vh;
  color: black;

}

.submitbtn {

  background: #1a1a1a;
  color: white;
  font-size: 1.7vh;
  border: none;
  transition: 0.3s !important;
  padding: 3% 5%;
  border-radius: 3px;
  margin-top: 1%;

}

.submitbtn:hover {


}

.pending-message {

  color: #fff;
  
}

.failure-message {

  color: #f00;
  
}

#letusknow {

  grid-row: 2;
  grid-column: 2;

}

.language-link {

  grid-column: 2;
  align-self: end;

}

.notfound {

  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template: 10vh 1fr 50vh 1fr / 1fr 50vh 1fr;

}

.notfoundbubble {

  grid-column: 2;
  grid-row: 3;
  border-radius: 50%;
  border: solid black 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: black;
  font-family: Rajdhani;
  font-size: 12vh;

}

.notfoundbubble * {

  color: black;
  font-family: Rajdhani;
  font-size: 3vh;
  text-decoration: none;

}

u {

  text-decoration: underline !important;

}

::-webkit-scrollbar {

  width: 10px;
  background: #1a1a1a;

}

::-webkit-scrollbar-thumb {
  background: #eee;    
  border: 3px solid transparent;
  border-radius: 9px;
  background-clip: content-box;
}


.formcontact ::placeholder { 

  color: #00000066;
  opacity: 1;
  font-size: 80%;

}

.formcontact :-ms-input-placeholder { 

  color: #00000066;
  font-size: 80%;

}

.formcontact ::-ms-input-placeholder {

  color: #00000066;
  font-size: 80%;

}

[type="search"]::-webkit-search-cancel-button {

  -webkit-appearance: none;
  appearance: none;
  height: 10px;
  width: 10px;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE2LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPg0KPCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCINCgkgd2lkdGg9IjEyMy4wNXB4IiBoZWlnaHQ9IjEyMy4wNXB4IiB2aWV3Qm94PSIwIDAgMTIzLjA1IDEyMy4wNSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTIzLjA1IDEyMy4wNTsiDQoJIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPGc+DQoJPHBhdGggZD0iTTEyMS4zMjUsMTAuOTI1bC04LjUtOC4zOTljLTIuMy0yLjMtNi4xLTIuMy04LjUsMGwtNDIuNCw0Mi4zOTlMMTguNzI2LDEuNzI2Yy0yLjMwMS0yLjMwMS02LjEwMS0yLjMwMS04LjUsMGwtOC41LDguNQ0KCQljLTIuMzAxLDIuMy0yLjMwMSw2LjEsMCw4LjVsNDMuMSw0My4xbC00Mi4zLDQyLjVjLTIuMywyLjMtMi4zLDYuMSwwLDguNWw4LjUsOC41YzIuMywyLjMsNi4xLDIuMyw4LjUsMGw0Mi4zOTktNDIuNGw0Mi40LDQyLjQNCgkJYzIuMywyLjMsNi4xLDIuMyw4LjUsMGw4LjUtOC41YzIuMy0yLjMsMi4zLTYuMSwwLTguNWwtNDIuNS00Mi40bDQyLjQtNDIuMzk5QzEyMy42MjUsMTcuMTI1LDEyMy42MjUsMTMuMzI1LDEyMS4zMjUsMTAuOTI1eiIvPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPC9zdmc+DQo=);
  background-size: 10px 10px;

}

h2 {

  font-weight: normal;

}