@import url('https://fonts.googleapis.com/css2?family=Rajdhani&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap');


@font-face { 
  font-family: "FrutigerNext"; 
  src: url("./frutigernextregular.woff"); 
}

* {
  font-family: FrutigerNext;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.Navsidebar {

  display: grid;
  grid-template: 1fr / 19.3111% 80%;
  z-index: 1000;

}

.sidenav {
  height: auto;
  padding-bottom: 1.5%;
  min-width: 250px;
  position: fixed;
  left: 0vw;
  top: 6vh;
  background-color: #333333;
  background-size: cover;
  overflow-x: hidden;
  text-align: left;
  margin: 0;
  transform: scale(0, 0) rotate(-12deg);
  transform-origin: 0% 0%;
  transition: ease 200ms;
  z-index: 1000000;
  opacity: 0;
}

::-webkit-scrollbar {
  width: 0;
}

.menuimg {

  width: 3vh;

}

.railogo {
  width: 16vh;
  margin-left: 5vh;
  
}

.sidenav.active {
  opacity: 1;
  transform: scale(1, 1);
}

.color-clicked {
  height: 0;
  transform: scale(0, 0) rotate(12deg);
  transform-origin: 50% 0%;
  transition: ease 200ms;
}

.color-clicked.active {
  transform: scale(1, 1);
  height: auto;
  opacity: 1;
}

.circle {
  width: 30px;
  height: 30px;
  margin: auto;
  border-radius: 50%;
  border: solid black 2px;
  cursor: pointer;
}


.sidenav a {
  padding: 6px 8px 6px 16px;
  text-decoration: underline transparent;
  font-size: 19px;
  color: #ddd;
  display: block;
}

.sidenav a:hover {
  text-decoration: underline red;
  font-size: 22px;
  color: white;
}


#yeetfield {

  width: 100vw;
  grid-column: 2;

}

.yeetfield {

  height: 100vh;
  z-index: 100000;
  pointer-events: none;
  position: fixed;

}

.yeetfield.active {

  pointer-events: all;

}


.sidenav a:hover {
  color: #f1f1f1;
}

.add-xyz{

  color: black;
  width: 100%;
  text-align: center;
  display:flex;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  
  top: 6vh;
  z-index: 0;
   
}

.add-calendar{

  color: black;
  width: 100%;
  text-align: center;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  
  
  top: 6vh;
  z-index: 0;
  margin-top: 4vh;
  position: fixed;

}

.topnav {

  display: grid;
  grid-template: 1vh 4vh 1vh / 1.5vw 10vw 70vw 10vw 1fr;
  position: fixed;
  align-self: center;
  width: 100%;
  z-index: 1000;
  
  background: white;
  box-shadow: #f0f1f4 0 1px;
  

}

@media (min-width: 768px) {
  .topnav{
    grid-template: 1vh 4vh 1vh / 8vw 10vw 70vw 10vw 1fr;
    
  }
  .sidenav {
    left: 6vw;
  }
  
  
}

@media (max-width: 768px) {
  .add-xyz input,.add-xyz select,.add-xyz textarea{
    width: 70%;
  }
  .add-xyz button{
    width: 25%;
  }
  .add-calendar {
    grid-template-columns: 1fr 9fr 1fr;
  }
  
}

@media (min-width: 770px) and (max-width: 1000px)  {
  .add-calendar {
    grid-template-columns: 1fr 7fr 1fr;
  }
  .contentform input,.contentform select,.add-xyz textarea{
    width: 60%;
  }
  .add-xyz button{
    width: 15%;
  }
  
}

@media (max-width: 1300px) and (min-width: 1000px){
  .add-calendar {
    grid-template-columns: 1fr 4fr 1fr;
  }
  .contentform input,.contentform select,.add-xyz textarea{
    width: 40%;
  }
  .add-xyz button{
    width: 12.5%;
  }
  
}

@media (min-width: 1300px) {
  .add-calendar {
    grid-template-columns: 1fr 2fr 1fr;
  }
  .contentform input,.contentform select,.add-xyz textarea{
    width: 30%;
  }
  .add-xyz button{
    width: 7.5%;
  }
  
}
.topnav img {
  grid-row: 2;
  grid-column: 2;
  align-self: center;
}

.topnav h1 {

  grid-row: 2;
  grid-column: -3;
  margin: 0;

}

.add-xyz input{
  border-radius: 5px;
  border: 0.5px solid grey;
 
  box-sizing: border-box;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 10px;
  color: black;
  font-size: 15px;
  font-family: Arial, Helvetica, sans-serif;
  margin-top: 5px;
}

.add-xyz button{
  border-radius: 5px;
  border: 0.5px solid grey;
  
  padding-top: 1%;
  padding-bottom: 1%;
  color: white;
  font-size: 15px;
  background-color: black;
}

.add-xyz textarea{
  border-radius: 5px;
  border: 0.5px solid grey;
  box-sizing: border-box;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 10px;
  color: black;
  font-size: 15px;
  font-family: Arial, Helvetica, sans-serif;
  margin-top: 5px;
}

.contentform{
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
}

.add-xyz form{
  width: 75%;
  align-self: center;
}

.add-xyz select{
  border-radius: 5px;
  border: 0.5px solid grey;

  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 10px;
  color: grey;
  font-size: 15px;
  font-family: Arial, Helvetica, sans-serif;
  margin-top: 5px;
}

.icons{
  width: 30px;
  height: 30px;
  padding-right: 4%;
  filter: invert(100%);
  display: none;
}

.addjesus{
  width: 60%;
  margin-left: 30vw;
  margin-top: 2vh;
  margin-bottom: 5vh;
}

.calendar {
  grid-column: 2;
  background-color: white;
  
  border-radius: 25px;
  
}



.fc .fc-button-group > .fc-button {
  background-color: black;
}

.fc .fc-toolbar {
  margin-bottom: 0.5rem !important;
}


.fc .fc-button-primary:not(:disabled):active:focus, .fc .fc-button-primary:not(:disabled).fc-button-active:focus {
  box-shadow: none !important;
}

.fc-direction-ltr .fc-button-group > .fc-button {
  box-shadow: none !important;
}

yes {
  color: green;
}

no {
  color: red;
}

.tableapi td, .tableapi tr{
  text-align: center;
  padding: 0.6vh 0;
  border-radius: 7.5px;
  color: whitesmoke;
  color: black;
}

.tableapi table {
  width: 100%;
}

.tableapi{ 
  width: 80%;
  text-align: center;
  border-radius: 10px;
  align-self: center;
}


.tableapi button{
  width: 100%;
  border: none;
  background: none;
  border-radius: 0;
  color: black;
  text-decoration: underline;
  cursor: pointer;
}

.tableapi .accept:hover{
  border-radius: 5px;
  margin-top: 5%;
  margin-bottom: 5%;
  margin-right: 1.5%;
  margin-left: 1.5%;
  background-color: green;
  color: white;
}

.tableapi .decline:hover{
  border-radius: 5px;
  margin-top: 5%;
  margin-bottom: 5%;
  margin-right: 1.5%;
  margin-left: 1.5%;
  background-color: red;
  color: white;
}


.great-success {

  color: #0c0;
  margin-top: 5vh;
  margin-bottom: 5vh;

}

.not-so-great-success {

  color: #f00;
  margin-top: 5vh;
  margin-bottom: 5vh;

}

.top-content h1{
  margin-top: 0;
  padding-top: 2%;
  font-size: 2rem;

}


.custom-ui {
  width: 100%;
  text-align: center;
  border-radius: 15px;
  display: grid;
  grid-template-rows: 3fr 1fr;
  justify-content: center;
  grid-template-columns: 30vw;  
}



.popupconfirm-title {
  background: white;
  width: 100%;
  justify-content: center;
  padding-top: 2%;
  padding-bottom: 2%;  
  border-radius: 20px 20px 0 0;
  
  
}

.popupconfirm-buttons {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  justify-content: center;
  background-color: #333333;
  width: 100%;
  border-radius: 0px 0px 14.7px 14.7px;
}
.popupconfirm-buttons button {
  border-radius: 15px;
  border: none;
  margin: 10%;
  width: 70%;
  align-self: center;
  background-color: #333333;
  color: white;
  font-size: 1rem;
  
  
}

.popupconfirm-buttons #delete {
  grid-column: 1;
  justify-self: center;
}

.popupconfirm-buttons #cancel {
  grid-column: 3;
  justify-self: center;
}

.popupconfirm-buttons #edit {
  grid-column: 2;
  justify-self: center;
}




.custom-ui h1,p{
  margin: 0;
  padding-left: 5%;
  padding-right: 5%;
}

.custom-ui p {
  font-size: 1rem;
}

.react-confirm-alert-overlay {

    background: rgba(255, 255, 255, 0.75) !important;
    backdrop-filter: blur(3px) !important;
}

.react-confirm-alert {
  width: 80vw;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-self: center;
}

.custom-ui2 {
  background-color: whitesmoke;
  width: 50%;
  text-align: center;
  border-radius: 15px;
  align-self: center;
  
  
}

.popupstage-content {
  width: 100%;
  justify-content: center;
  margin-top: 2%;
  margin-bottom: 2%;
  
}

.popupstage-buttons {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  justify-content: center;
  background-color: #333333;
  width: 100%;
  border-radius: 0px 0px 15px 15px;
  align-self: center;

}

.popupstage-buttons button {
  border-radius: 15px;
  border: none;
  margin: 10%;
  width: 70%;
  align-self: center;
  background-color: #333333;
  color: white;
  font-size: 1rem;


}

.popupstage-buttons #cancel {
  grid-column: 3;

}


.sketch-picker {
  margin: 10px auto 20px auto;
}

.fc-icon-chevron-left:before {
  color: white;
}

.fc-icon-chevron-right:before {
  color: white;
}


.loginfirst {

  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template: 10vh 1fr 50vh 1fr / 1fr 50vh 1fr;

}

.loginfirstbubble {

  grid-column: 2;
  grid-row: 3;
  border-radius: 50%;
  border: solid black 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: black;
  font-family: Rajdhani;
  font-size: 12vh;

}

.loginfirstbubble * {

  color: black;
  font-family: Rajdhani;
  font-size: 3vh;
  text-decoration: none;

}

u {

  text-decoration: underline !important;

}

.popupconfirm-title {

  background: white;

}

button[title="+"]{

  border-radius: 50% !important;
  text-align: center;
  width: 38.8px;

}

.pointer-cursor {

  cursor: pointer;

}